<template>
    <transition name="fade">
      <div v-if="show" class="messages" :data-type="type">
          <h1>{{message}}</h1>
      </div>
    </transition>
</template>

<script>
export default {
    name:'Messages',
    data(){
        return{
            show:false
        }
    },
    mounted(){
        this.show = true
    },
}
</script>

<style>
.messages{
    backdrop-filter: blur(10px);
    color:white;
    width:100%;
    height:100%;
    position: absolute;
    top:0;
    z-index:1000;
    border-radius:inherit;
    transition:all 0.5s ease-in-out ;
}
.messages[data-type="error"]{
    background-color: rgb(165, 42, 42);
}
.messages[data-type="warn"]{
    background-color: #EED202;
}
.messages[data-type="success"]{
    background-color: #408140;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>