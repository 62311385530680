<template>
    <div v-if="isActive" class="addresses"> 
        <h1 class="address-header"> {{title}} </h1>
        <div class="address-container">

            <div class="address-cards"  v-if="Addresses.length" >

                <AddressCard v-for="(address,index) in Addresses" v-bind:key="index+9" v-bind:details="address" v-bind:imageIndex="index">
                    <div class="address-overlay">
                        <button v-on:click="setDefaultAddress(address.a_id)" v-if="address.a_id != defaultAddress">Set as default</button>
                        <h3 v-on:click="setDefaultAddress(address.a_id)" v-else>Default</h3>
                        <button v-on:click="deleteAddress(address)">Delete</button>
                    </div>
                </AddressCard>

                <AddAddress v-if="addAddress"></AddAddress>
                <div class="address-card-add" v-else>
                    <div class="address-content">
                        <button class="address-add" v-on:click="addAddress = !addAddress"><h1>+</h1></button>
                    </div>
                </div>
            </div>

            <div class="no-address" v-else>
                <div class="no-address-card" v-if="!addAddress">
                    <div class="address-card-add" >
                        <div class="address-content">

                        </div>
                    </div>
                    <h3>Looks Like there are no saved addresses...</h3>
                    <button class="address-add" v-on:click="addAddress = !addAddress">Add Address</button>     
                </div>

                <AddAddress v-else></AddAddress>
            </div>

        </div>
    </div>
</template>
<script>
import AddAddress from '../../component/AddAddress.vue'
import AddressCard from '../../component/AddressCard.vue'
const axios = require('axios')
export default {
    name:"Addresses",
    components: { AddressCard, AddAddress },
    data(){
        return{
            title:'Addresses',
            isActive:true,
            addAddress:false,
            defaultAddress:null,
            addAddresses:
                {
                name:null,
                number:"",
                line1:"",
                line2:"",
                city:"",
                pin:"",
                state:"",
                country:"",

            }
            ,
            Addresses:[
            //     {
            //     name:"Cu Circuits",
            //     number:"",
            //     line1:"Sadullah Street",
            //     line2:"T.nagar",
            //     city:"Chennai",
            //     pin:"600017",
            //     state:"Tamil Nadu",
            //     country:"India",
            //     img:null
            // }
            ]
        }   
    },
    computed:{
        hasAddress:function(){
            if(this.addAddresses.city&&this.addAddresses.pin&&this.addAddresses.state&&this.addAddresses.country)
                return false
            return true
        }
    },
    mounted(){
        var inst = this.$message('LODADING...','loading')
        this.getAddresses(inst)
    },
    methods:{
        getAddresses(inst){
            axios.get(process.env.VUE_APP_ADDRESS_URL,{ headers: {
                    Authorization: 'bearer ' + this.$keycloak.token, //the token is a variable which holds the token
            }}).then((data)=>{
            // console.log(data.data[0])
            this.defaultAddress = data.data[0]?.isdefault?.a_id
            let Addresses = data.data[0]?.user_address
            Addresses?.forEach((address)=>{
                address.img = null
            })
            if(Addresses)
                this.Addresses = Addresses
            this.$closeMessage(inst)
        })
        .catch(error=>{
                this.$updateMessage(inst,error,'error')
            })
        },
        clickAddAddress(){
            let address = {
                name:this.addAddresses.name,
                line1:this.addAddresses.line1,
                line2:this.addAddresses.line2,
                city:this.addAddresses.city,
                pin:this.addAddresses.pin,
                state:this.addAddresses.state,
                country:this.addAddresses.country
            } 
            axios.post(process.env.VUE_APP_ADDRESS_URL,{
                    address,
                    isDefault:null
            },{ headers: {
                    Authorization: 'bearer ' + this.$keycloak.token, //the token is a variable which holds the token
            }})
            .then((data)=>{
                // console.log(data)
                        this.Addresses.push({
                            a_id: data.data,
                            address_name:this.addAddresses.name,
                            line_1:this.addAddresses.line1,
                            line_2:this.addAddresses.line2,
                            city:this.addAddresses.city,
                            pincode:this.addAddresses.pin,
                            address_state:this.addAddresses.state,
                            country:this.addAddresses.country,
                            img:null
                        })
                        this.addAddress=false
                        this.addAddresses=
                        {
                            name:null,
                            number:"",
                            line1:"",
                            line2:"",
                            city:"",
                            pin:"",
                            state:"",
                            country:"",
                        }
                    })

        },
        deleteAddress(address){
            axios.delete(process.env.VUE_APP_ADDRESS_URL,{ headers: {
                        Authorization: 'bearer ' + this.$keycloak.token, //the token is a variable which holds the token
                },
                data: { address: address } }).then(()=>{
                    const index = this.Addresses.findIndex(a => {return a.a_id === address.a_id})
                    this.Addresses.splice(index , 1)
                })
        },
        setDefaultAddress(id){
            axios.post(process.env.VUE_APP_ADDRESS_URL+'/default',{ id: id },{ headers: {
                        Authorization: 'bearer ' + this.$keycloak.token, //the token is a variable which holds the token
                },
                }).then(()=>{
                    this.defaultAddress = id
                })
        }
    }
}
</script>
<style>
h1{
    display:flex;
    justify-content: center;
    align-items: center;
    margin:0;
    height:100%;
    width:100%;
    text-align: center;
    /* color:black; */
}
h2{
    display:flex;
    justify-content: left;
    align-items: center;
    margin:0;
    height:100%;
    width:100%;
    /* color:black; */
}
.addresses button{
    margin:0px;
    background-color: black;
    color:white;
}
.addresses{
    height:100%;
    width:100%;
    border-radius:inherit;
    position:relative;
    background: linear-gradient(220deg, #b9b9b9, #ffffff);
    background-size: 300% 300%;
    background-position: 0 30%;
    -webkit-animation: AnimationName 17s ease infinite;
    -moz-animation: AnimationName 17s ease infinite;
    animation: AnimationName 17s ease infinite;
}
.address-header{
    display:inline-flex;
    height:10%;
    width:90%;
    justify-content: flex-end;
    align-items: center;
}
.address-add{
    margin:0;
    width:10%;
}
.address-add h1{
    justify-content: center !important;
}

.address-container{
    display:flex;
    justify-content: center;
    width:100%;
    height:90%;
    overflow-x: auto;
    /* background-color: rgba(0, 0, 0, 0.123); */
}
.address-cards{
    
    display:grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(450px ,1fr));
    grid-auto-rows: 220px;
    grid-gap:3%;
    height:auto;
    width:85%;
    
}

.address-card{
    position: relative;
    display:flex;
    align-items: center;
    justify-content: center;
    /* background-color: rgba(0, 0, 0, 0.452); */
    height:100%;
    width:100%;
    border-radius: 5px;
    /* background-image: url(../../../assets/local-map2.png); */
    background-repeat: no-repeat;
    /* background:  linear-gradient(0deg, transparent, white 90%) ,url(../../../assets/local-map2.png); */
    /* background-blend-mode: screen; */
    background-position: top;
    background-size: 150%;
    box-shadow: 0 0 30px -20px;
    /* filter:brightness(10); */
    /* box-shadow: inset 0px 0px 60px -40px; */
}

.address-content{
    /* background-color: black; */
    height:auto;
    width:95%;
}
.address-content h1{
    /* background-color: black; */
   justify-content: flex-start;
}
.address-card-add .address-content > input{
    /* background-color: black; */
    height:auto;
    margin:2px;
    outline:none;
    border:none;
    border-radius:2px;
}
.address-overlay{
    transition: all 0.5s ease-in-out;
    position:absolute;
    backdrop-filter: blur(0px);
    background-color: rgba(0, 0, 0, 0.418);
    width: 100%;
    height: 100%;
    border-radius:5px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
}
.address-overlay:hover{
    opacity: 1;
    backdrop-filter: blur(2px);
}

.address-overlay button{
    height:40px;
    margin:1.5%;
}

.address-overlay h3{
    font-weight:500;
    color:white;
}

.address-name{
    letter-spacing: 3px;
    width:90%;
}
.address-line-1{
    font-weight:200;
    width:90%;
    
}
.address-line-2{
    font-weight:200;
    width:90%;
}
.address-city{
    display: inline-flex;
    width:50%;
    font-weight:200;
}
.address-pin{
    display:inline-flex;
    width:50%;
    font-weight:200;
}
.address-state{
    font-weight:200;
    width:90%;
    
}
.address-country{
    justify-content:flex-end;
    width:90%;
}

.no-address{
    width:100%;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
}

.no-address button{
    margin:2.5%;
    height:50px;
}

.no-address-card{
    height:100%; 
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.no-address .address-card-add{
    height:220px;
    width:600px;
    margin-bottom:50px;
}
</style>