<template>
  <div id="app">
  <div>
    <router-view name='header'/>
  </div>
  <div>
     <router-view/>
  </div>
  </div>
</template>
<script>
export default {
  mounted(){
      this.$getSettings()
  },
  methods:{

  }
}
</script>
<style>
body{
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  
}
h1{
  font-weight:500;
}
h2{
  font-weight:400;
}
h3{
  font-weight:300;
}
h4{
  font-weight:200;
}
h5{
  font-weight:100;
}
h1,h2,h3,h4,h5{
  font-weight:300;
  letter-spacing: 2px;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: neue-haas-unica,sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  width:100vw;
  height:100vh;
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  
}
#nav a.router-link-exact-active {
  color: #42b983;
}

button{
    font-family: neue-haas-unica,sans-serif!important;
    font-style: normal;
    transition: all 0.2s ease-in-out;
    border:none;
    font-size: 16px;
    border-radius: 5px;
    letter-spacing: 3px;
    margin:2.5%; 
}

button:active{
  opacity:0.5;
}

button:hover{
  cursor:pointer;
}

.profile-tabs button:disabled{
  opacity:1;
  pointer-events:none;
}

button:disabled{
  opacity:0.2;
  pointer-events:none;
}

</style>