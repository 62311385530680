import Vue from 'vue'
import VueRouter from 'vue-router'
import Header from '../components/Header.vue'
import Home from '../views/Profile.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    components: { default: Home, header: Header }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
