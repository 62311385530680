const amex  = require('./amex.png')
const cartes_bancaires  = require('./cartes_bancaires.png')
const diners    = require('./diners.png')
const discover  = require('./discover.png')
const jcb   = require('./jcb.png')
const mastercard    = require('./mastercard.png')
const visa  = require('./visa.png')
const unionpay  = require('./unionpay.png')
const chip = require('./chip.png')
const expimg = require('./expimg.png')



module.exports = {
    amex,
    cartes_bancaires,
    diners,
    discover,
    jcb,
    mastercard,
    visa,
    unionpay,
    chip,
    expimg
}