<template>
<div class="image-edit-wrapper">
    <button v-on:click="$emit('show',false)" class="close-button" v-if="!loading">X</button>
    <div id="drop_zone" class="drop-zone" v-on:drop="dropHandler($event)" v-on:dragover="dragOverHandler($event)" v-on:dragleave="dragLeaveHandler($event)" v-if="!loading && !error">
        <div id="image-preview" class="image-preview" :style="droppedFiles ? '':'display:none'">
            <button v-on:click="setImage()">Set image</button>
        </div>
        <p v-if="!droppedFiles">Drop an image here //</p>
        <input v-if="!droppedFiles" type="file" v-on:change="change($event)" name="file" id="file" class="inputfile"/> 
        <label v-if="!droppedFiles" for="file" v-on:change="change($event)">{{droppedFiles?droppedFiles.name:null||"Choose an image"}}</label>

    </div>
    <div class="loader" v-else-if="loading">
        <h1>Loading...</h1>
    </div>
    <div class="error" v-else>
        <h1>Error</h1>
    </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      droppedFiles: null,
      loading:false,
      error:false
    };
  },
  methods: {
    dropHandler(ev) {
        this.dragLeaveHandler(ev)
      // console.log("File(s) dropped");

      // Prevent default behavior (Prevent file from being opened)
      ev.preventDefault();

      if (ev.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (var i = 0; i < ev.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (ev.dataTransfer.items[i].kind === "file") {
            this.previewAnduploadImage(ev.dataTransfer.items[i].getAsFile());
            var file = ev.dataTransfer.items[i].getAsFile();
            // console.log("... file[" + i + "].name = " + file.name);
            this.droppedFiles = file;
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        for (var j = 0; j < ev.dataTransfer.files.length; j++) {
          this.previewAnduploadImage(ev.dataTransfer.files[j].getAsFile());
          // console.log("... file[" + j + "].name = " + ev.dataTransfer.files[j].name);
          this.droppedFiles = ev.dataTransfer.files[j];
        }
      }
    },
    dragOverHandler(ev) {
        ev.preventDefault();
      // console.log("File(s) in drop zone");
      ev.target.classList.add('zone')
      ev.target.style.backgroundColor="white"
      ev.target.style.color="black"
      // Prevent default behavior (Prevent file from being opened)
      ev.preventDefault();
    },
    dragLeaveHandler(ev){
      ev.preventDefault();
      ev.target.classList.remove('zone')
      ev.target.style.backgroundColor="black"
      ev.target.style.color="white"
    },
    change(e) {
      // console.log("change", e.target.files);
      // console.log("change");
      var fileName = "";
      if (this.files && this.files.length > 1)
        fileName = (this.getAttribute("data-multiple-caption") || "").replace("{count}",this.files.length);
      else fileName = e.target.value.split("\\").pop();
      // console.log(fileName)
      if (fileName) this.droppedFiles = e.target.files[0];
      this.previewAnduploadImage(e.target.files[0]);
    },  
    previewAnduploadImage(image) {
    var imagePreviewRegion = document.getElementById("image-preview");
    imagePreviewRegion.children.forEach(element => {
        if(element.nodeName !== "BUTTON")
            element.remove()
    });
    // container
    var imgView = document.createElement("div");
    imgView.className = "image-view";
    imagePreviewRegion.prepend(imgView);

    // previewing image
    var img = document.createElement("img");
    imgView.prepend(img);

    // progress overlay
    var overlay = document.createElement("div");
    overlay.className = "overlay";
    imgView.prepend(overlay);


    // read the image...
    var reader = new FileReader();
    reader.onload = function(e) {
        img.src = e.target.result;
    }
    reader.readAsDataURL(image);

    // // create FormData
    // var formData = new FormData();
    // formData.append('image', image);

    // // upload the image
    // var uploadLocation = 'UPLOAD_LOCATION';

    // var ajax = new XMLHttpRequest();
    // ajax.open("POST", uploadLocation, true);

    // ajax.onreadystatechange = function(e) {
    //     if (ajax.readyState === 4) {
    //         if (ajax.status === 200) {
    //             // done!
    //         } else {
    //             // error!
    //         }
    //     }
    // }

    // ajax.upload.onprogress = function(e) {

    //     // change progress
    //     // (reduce the width of overlay)

    //     var perc = (e.loaded / e.total * 100) || 100,
    //         width = 100 - perc;

    //     overlay.style.width = width;
    // }

    // ajax.send(formData);

},
setImage(){
    const formData = new FormData();
    formData.append('avatar', this.droppedFiles);
    this.loading=true
    axios.post(process.env.VUE_APP_ABOUT_URL+'/cover/upload',formData,{ headers: {
                    Authorization: 'bearer ' + this.$keycloak.token, //the token is a variable which holds the token
            }}).then(()=>{
                this.$getProfileCover()
                this.loading = false
                this.$emit("show",false)
            }).catch(()=>{
                this.loading = false
                this.error = true
            })
}

  }
};
</script>

<style>
.image-edit-wrapper{
    position:fixed;
    inset:25% 25% 25% 25%;
    background-color: black;
    border-radius:5px;
    color:white;
    transition:all 250ms ease-in-out;
    z-index:100;
}
.close-button{
    position:fixed;
    inset:15% auto auto 20%;
    background-color: #000;
    color:white;
    border-radius:50%;
    width:35px !important;
    height:35px !important;
    transition:all 50ms ease-in-out !important;
}
.drop-zone{
    height:100%;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: inherit;
    transition:all 250ms ease-in-out;
}
.drop-zone p{
    width:auto;
    /* transition:all 250ms ease-in-out; */
}
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
    cursor: pointer;
    margin-left:5px;
    padding:1px;
    border-radius: 2px;
    transition:all 250ms ease-in-out
}

.inputfile + label * {
	pointer-events: none;
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: white;
    color:black;
}

.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}
.image-preview{
    height:100%;
    width:100%;
}
.image-view{
    height:80%;
    width:100%;
}
.image-view img{
    height:90%;
    width:auto;
}
.zone *{
    pointer-events: none;
}

.loader {
  position: absolute;
  inset:43% 25% 43% 25%;
  background: #fff !important;
  color: #000 !important;
  mix-blend-mode: difference;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.5em;
  padding: 10px;
  display:flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.loader h1 {
  color: #fff !important;
  mix-blend-mode: difference;
  margin:0;
}

.loader:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 100%;
  background: #000;
  animation: animate 3s linear infinite;
  border-radius: 2px;
}

@keyframes animate {
  0% {
    left: 0;
  }
  50% {
    left: calc(100% - 80px);
  }
  100% {
    left: 0;
  }
}

.error{
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>