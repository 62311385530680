<template>
  <div
    v-if="isActive"
    class="who-am-i"
    :style="
      $img.img
        ? {
            backgroundImage: 'url(' + $img.img + ')',
            backgroundColor: background,
          }
        : { backgroundColor: background }
    "
  >
    <div class="inside">
      <div class="cover-img">
        <div class="cover-image-edit">
          <img :src="$img.cover ? $img.cover : 'data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs'" width="0" height="0" alt="" />
          <div class="edit-image-buttons" v-if="$img.cover">
            <button v-on:click="showCoverEdit = true">Edit</button>
            <button v-on:click="removeCoverImage()">Remove</button>
          </div>
          <div class="add-image-buttons" v-else>
            <button v-on:click="showCoverEdit = true">Add cover image</button>
          </div>
        </div>
        <img src="" alt="" />
      </div>
      <div class="info-container">
        <div class="image-edit-container" v-if="showImageEdit">
          <ProfileImage v-on:show="showImageEdit = $event"></ProfileImage>
        </div>
        <div class="image-edit-container" v-if="showCoverEdit">
          <CoverImage v-on:show="showCoverEdit = $event"></CoverImage>
        </div>
        <div class="profile-image-edit">
          <img :src="img" />
          <div class="edit-image-buttons" v-if="$img.img">
            <button v-on:click="showImageEdit = true">Edit</button>
            <button v-on:click="removeImage()">Remove</button>
          </div>
          <div class="add-image-buttons" v-else>
            <button v-on:click="showImageEdit = true">
              Add Profile Picture
            </button>
          </div>
        </div>
        <div class="name">
          <div>
            <h1>
              {{ $keycloak.tokenParsed ? $keycloak.tokenParsed.given_name : ""}}
              {{ $keycloak.tokenParsed ? $keycloak.tokenParsed.family_name : "" }}
            </h1>
          </div>
          <h2>{{ $keycloak.tokenParsed ? $keycloak.tokenParsed.preferred_username : "" }}</h2>
        </div>
        <div class="account-type">
          <h3>Account type</h3>
          <h1>Individual</h1>
        </div>
        <div class="email">
          <h3>Email</h3>
          <h1>{{$keycloak.tokenParsed ? $keycloak.tokenParsed.email : ""}}</h1>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
import ProfileImage from "../../component/ProfileImage.vue";
import CoverImage from "../../component/CoverImage.vue";
// var isAdvancedUpload = function() {
//   var div = document.createElement('div');
//   return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
// }();

export default {
  name:'About',
  components: { ProfileImage, CoverImage },
  props: ["profileImage","coverImage"],
  data() {
    return {
      title: "About",
      isActive:true,
      background: "black",
      droppedFiles: null,
      showImageEdit: false,
      showCoverEdit: false,
    };
  },
  computed: {
    img: function () {
      return this.$props.profileImage;
    },
    imgCover: function () {
      return this.$props.coverImage;
    },
  },
  mounted() {
    if(!this.$keycloak.tokenParsed){
      this.$message("Not Logged in",'error')
    }
  },
  methods: {
    removeImage() {
      axios
        .delete(process.env.VUE_APP_ABOUT_URL, {
          headers: {
            Authorization: "bearer " + this.$keycloak.token, //the token is a variable which holds the token
          },
        })
        .then(() => {
          this.$getProfileImage();
        });
    },
    removeCoverImage() {
      axios
        .delete(`${process.env.VUE_APP_ABOUT_URL}/cover`, {
          headers: {
            Authorization: "bearer " + this.$keycloak.token, //the token is a variable which holds the token
          },
        })
        .then(() => {
          this.$getProfileCover();
        });
    },
  },
};
</script>
<style scoped>
hr {
  margin: 0;
  width: 50%;
  /* height:1px; */
  background-color: beige;
}
h1 {
  margin: 0;
  height: 10%;
  width: auto;
  text-align: left;
  /* padding:1%; */
  color: white;
  /* text-align: end; */
}
h3 {
  text-align: left;
  color: rgb(131, 131, 131);
}
.inside {
  backdrop-filter: blur(30px) brightness(25%);
  border-radius: inherit;
  height: 100%;
  display: flex;
  /* mix-blend-mode: screen; */
}
.who-am-i {
  /* background-color: rgb(63, 77, 90); */
  height: 100%;
  width: 100%;
  border-radius: inherit;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position:relative
}
.who-title {
  display: flex;
  height: 10%;
  width: 90%;
  justify-content: flex-end;
  align-items: center;
}
.cover-img {
  width: 100%;
  height: 35%;
  background-color: rgba(93, 93, 93, 0.315);
  position: absolute;
  z-index: -1;
}
.cover-image-edit {
  width: 100%;
  height: 100%;
  background-color: rgba(93, 93, 93, 0.315);
  position: relative;
}
.cover-image-edit img {
  width:100%;
  height:100%;
  background-color: rgba(93, 93, 93, 0.315);
  margin:0;
  object-fit: cover;
  object-position: 0% 50%;
}
.cover-image-edit .edit-image-buttons {
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  justify-content: flex-end;
  align-items: flex-start;
}
.cover-image-edit .add-image-buttons {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  position:absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.info-container {
  width: 100%;
  height: 90%;
  display: grid;
  /* justify-content: space-between; */
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  column-gap: 1%;
  row-gap: 1%;
  margin-top: auto;
}
.profile-image-edit {
  /* width:90%; */
  /* height:100%; */
  position: relative;
  
}

.profile-image-edit img {
  width: 50%;
  height: auto;
}

.add-image-buttons button {
  text-align: center;
}

.edit-image-buttons {
  position: absolute;
  inset: auto 25% 5.2% auto;
  width: 50%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(transparent, black);
  border-radius: 5px;
}

.name {
  width: 100%;
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  grid-column-start: 2;
  grid-column-end: 4;
  flex-direction: column;
}
.name div {
  width: auto;
}
.name div h1 {
  width: auto;
  text-align: center;
}
.name > h2 {
  width: 40%;
  text-align: right;
  opacity: 0.5;
  color: white;
  margin: 0;
}
.language h1 {
  text-align: center;
}
.image-edit-container {
  background-color: rgba(255, 255, 255, 0.808);
  position: fixed;
  height: 100%;
  width: 100%;
  inset: 0 0 0 0;
  z-index: 1000;
}
</style>