// var ComponentClass = Vue.extend(messageComponent)
// var instance = new ComponentClass()
// instance.error = message
// instance.$mount()
// console.log(instance.$el)

import Vue from 'vue'
import messageComponent from './messages.vue'





const messages = {
    install(){
        // Vue.prototype.$message = function(message,type,reMountComponent = true,removeMessage = null){
        //         console.log("From plugin " ,this , message)
        //         console.log(messageComponent)
        //         var ComponentClass = Vue.extend(messageComponent)
        //         this.error = true
        //         var instance = new ComponentClass()
        //         instance.message = message
        //         instance.type = type
        //         console.log("before",instance)
        //         instance.$mount()
        //         console.log("Component Element ", this.$el)
        //         console.log("instance Element " , instance.$el)
        //         this.$el.appendChild(instance.$el)
                
        //         instance.parent = this._self
        //         console.log(instance.$options)
    
        //         if(!reMountComponent) this.error = false
        //         if(removeMessage){
        //             const timeout = setTimeout(()=>{
        //                 console.log("Timeout")
        //                 if(!reMountComponent) this.error = false
        //                 console.log(instance)
        //                 instance.show = false
        //                 setTimeout(()=>{this.$el.removeChild(instance.$el)},1000)
        //                 delete this.Timeout
        //             },removeMessage)
        //             this.Timeout = timeout
        //         }
        // }
        /**
         * 
         * @param {String} message 
         * @param {String} [type=success|warn|error|loading] 
         * @param {Boolean} [reMountComponent=true] Should the component remount
         * @param {Integer} [removeMessage=null] Remove message after set time
         * @returns {Object} component pointer if type is 'loading'
         */
        Vue.prototype.$message = function(message,type,reMountComponent = true,removeMessage = null){
            if(!['error','success','warn','loading'].some(typ=> {return typ === type})) return
            // console.log("From plugin " ,this , message)
            // console.log(messageComponent)
            var ComponentClass = Vue.extend(messageComponent)
            this.error = true
            var loading = type === 'loading'
            var instance = new ComponentClass()
            instance.message = message
            instance.type = type
            instance.$mount()
            // console.log("before",instance)
            // console.log(this)
            // console.log("Component Element ", this.$el)
            // console.log("instance Element " , instance.$el)
            // console.log(instance)
            if(this.$el.nodeName === "#comment") return
            if(!reMountComponent && !loading) this.error = false
            if(removeMessage && !loading){
                const timeout = setTimeout(()=>{
                    // console.log("Timeout")
                    // instance.$destroy()
                    if(!reMountComponent) this.error = false
                    // console.log(instance)
                    instance.show = false
                    setTimeout(()=>{instance.$destroy()},1000)
                    delete this.Timeout
                    instance.$forceUpdate()
                },removeMessage)
                this.Timeout = timeout
            }
            this.$el.appendChild(instance.$el)
            if(type === 'loading') return instance
    }

    /**
     * 
     * @param {Component} instance 
     * @param {String} message 
     * @param {String} [type=success|warn|error|loading] 
     * @param {Boolean} [reMountComponent=true] Should the component remount
     * @param {Integer} [removeMessage=null] Remove message after set time
     * @returns {undefined}
     */
    Vue.prototype.$updateMessage = function(instance,message,type,reMountComponent = true,removeMessage = null){
        if(instance === null || instance === undefined) {
            console.warn("Component is missing")
            return
        }
        if (instance._isBeingDestroyed || instance._isDestroyed){
            console.warn("Trying to update already destroyed component or trying to update component that is being destroyed")
            return
        }
        instance.message = message
        instance.type = type
        instance.$forceUpdate()
        if(type === 'loading' || type === null || type === undefined || !['error','success','warn'].some(typ=> {return typ === type})) return
        if(!reMountComponent) this.error = false
        if(removeMessage){
            const timeout = setTimeout(()=>{
                // console.log("Timeout")
                // instance.$destroy()
                if(!reMountComponent) this.error = false
                // console.log(instance)
                instance.show = false
                setTimeout(()=>{instance.$destroy()},1000)
                delete this.Timeout
                instance.$forceUpdate()
            },removeMessage)
            this.Timeout = timeout
        }
        
    }
    Vue.prototype.$closeMessage = function(instance,reMountComponent = false){
        if(instance === null || instance === undefined) {
            console.warn("Component is missing")
            return
        }
        if (instance._isBeingDestroyed || instance._isDestroyed){
            console.warn("Trying to update already destroyed component or trying to update component that is being destroyed")
            return
        }
        if(!reMountComponent) this.error = false
        instance.show = false
        setTimeout(()=>{instance.$destroy()},1000)
    }
    } 
}

export default messages