<template>
<!-- <transition name="fade" appear> -->
        <div class="profile">
            <div class="profile-container">
                <div class="profile-sidebar">
                    <div class="profile-image">
                        <img :src="$img.img" v-if="$img.img">
                        <div v-else></div>
                    </div>
                    <div class="profile-tabs">
                        <template v-for="(view,name,index) in views" >
                            <!-- <div v-bind:key="index">

                            <button>{{view}}</button>
                            <button>{{index}}</button>
                            </div> -->
                            <button class="profile-buttons" v-on:click="clickView(index)" v-bind:key="index" :disabled="view.isActive" :style="view.isActive ? {backgroundColor:view.background,color:'white'}:{color:'black'}">{{view.title}}</button>
                        </template>
                    </div>
                </div>
                <div class="profile-view" id="view">
                    <Apps id="Apps" key="Apps"></Apps>
                    <About id="About" key="About" v-bind:profileImage="$img.img" v-bind:coverImage="$img.cover"></About>
                    <Security id="Security" key="Security"></Security>
                    <Privacy id="Privacy" key="Privacy"></Privacy>
                    <Addresses id="Addresses" key="Address"></Addresses>
                    <Payment id="Payment" key="Payment"></Payment>
                    <Settings id="Settings" key="Settings"></Settings>
                </div>
            </div>
        </div>
<!-- </transition> -->
</template>

<script>
import Apps from '../components/pages/profile/Apps'
import About from '../components/pages/profile/About'
import Security from '../components/pages/profile/Security'
import Addresses from '../components/pages/profile/Addresses'
import Payment from '../components/pages/profile/Payment'
import Settings from '../components/pages/profile/Settings'
import Privacy from '../components/pages/profile/Privacy'


export default {
  components: { Apps,About,Security,Addresses,Payment,Settings,Privacy },
    data(){
      return{
        children:this.$children,
        viewObj : {
            "Apps":null,
            "About":null,
            "Security":null,
            "Privacy":null,
            "Addresses":null,
            "Payment":null,
            "Settings":null
        }
      }
    },
    created(){
        // this.children = this.$children
        Object.seal(this.viewObj)
    },
    computed:{
        //This is to make sure buttons don't reorder themselves
        views:function(){

            this.children.forEach(child=>{
                this.viewObj[child.$options.name] = child
            })
            //Checking length here to listen for changes in children
            return this.children.length ? this.viewObj : []
        }
    },
    mounted(){
        // var element = document.getElementById("Profile-button")
        // element.children[0].classList.add("Nav-selected");
        this.$getProfileImage()
        this.$getProfileCover()
        Object.values(this.views).forEach(view=>{
            view.isActive=false
        })

        this.views.Apps.isActive = true

    },
    beforeDestroy(){
        var element = document.getElementById("Profile-button")
        element.children[0].classList.remove("Nav-selected");
    },
    methods:{
        clickView(i){
            Object.values(this.views).forEach((view, index) => {
                view.isActive = (index === i)
                // console.log(view.$options.name)
                // console.log(view)
                clearTimeout(view.Timeout)
                if(view.error && view.isActive){
                    const newKey = Math.ceil(Math.random() * 1000) + 100;
                    view.$vnode.key = newKey
                }
            })
        }
    }

}
</script>

<style>

img{
    width:50%;
    height:auto;
    margin:2% auto 2% auto;
    border-radius: 5px ;
    box-shadow: 0px 0px 60px -30px;
}
.profile{
    display:flex;
    /* background-color: antiquewhite; */
    height:90vh;
    width:100vw;
    align-items: center;
}

.profile .profile-buttons{
    height:5vh;
    margin:2.5%;
    width:auto;
    text-align:end;
    transition: all 0.2s ease-in-out;
}
.profile-container{
    display:inline-flex;
    border-radius:10px;
    /* background-color: black; */
    width:95%;
    height:95%;
    margin:auto;
    box-shadow: 0px 0px 50px -20px;
}
.profile-sidebar{
    border-radius: 5px 0 0 5px;
    background-color: #dadada65;
    backdrop-filter: blur(50px);
    width:20%;
    height:100%;
    z-index: 2;
    box-shadow: 20px 0px 40px -40px;
}
.profile-image{
    display:flex;
    align-items: center;
    justify-content: center;
    /* background-color: aquamarine; */
    width:100%;
    height:35%;
}
.profile-image div{
    background-color:#b4b4b471;
    /* background-color: aquamarine; */
    width:50%;
    height:60%;
    border-radius:5px;
}
.profile-tabs{
    display:flex;
    justify-content: center;
    flex-direction: column;
    /* height:auto; */
    height:65%;
}
.profile-view{
    border-radius: 0 5px 5px 0;
    /* background-color: rgba(255, 255, 0, 0.199); */
    width:80%;
    position:relative;
}
#stripe-iframe{
    width:500px;
    height:650px;
    position: absolute;
    z-index: 1000;
    inset:0;
    margin:auto;
    border: none;
    border-radius:5px;
    /* background-color: rgba(255, 255, 255, 0.173); */
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>