<template>
<div class="address-card" v-bind:style="getImage(address.img)">
    <div class="address-content">
        <h1 class="address-name">{{address.address_name}}</h1>
        <h2 class="address-line-1">{{address.line1 || address.line_1}}</h2>
        <h2 class="address-line-2">{{address.line2 || address.line_2}}</h2>
        <h2 class="address-city">{{address.city}}</h2>
        <h2 class="address-pin">{{address.postal_code || address.pincode}}</h2>
        <h2 class="address-state">{{address.state || address.address_state}}</h2>
        <h2 class="address-country">{{address.country}}</h2>
    </div>
    <slot></slot>
</div>
</template>

<script>
import axios from 'axios'
export default {
    props:{
        details:{
            type:Object,
            required:true
        }
    },
    data(){
        return {
            address:this.details
        }
    },
    mounted(){
        var address = JSON.parse(JSON.stringify(this.address));
        // console.log(address)
        delete address.address_name
        delete address.img
        delete address.a_id
        if(Object.values(address).some(val=>{return val})){  
            Object.values(address).map(val=>{
                if(val)
                    return val.replace(" ", "+")
            })
            // console.log(Object.values(address))
            const query = Object.values(address).join("+")
            // console.log(query)

            axios.get(`${process.env.VUE_APP_HEREMAPS_URL}/v1/geocode?q=${query}`)
            .then(data => {
                if(this.address.country.length == 2)
                    this.address.country = data.data.items[0].address.countryName

                this.address.img = `${process.env.VUE_APP_HEREMAPS_URL}/mia/1.6/tiltmap?ta=60&w=800&h=300&z=16&t=5&c=${data.data.items[0].position.lat},${data.data.items[0].position.lng}&nodot&f=0&nocp=`
            })
        }
    },
    methods:{
        getImage(img){
        if(!Object.values(this.address).some(val=>{return val})) return {}
           var i =  img?{background:`linear-gradient(0deg, transparent, white 90%),url(${img})`,backgroundSize:'150%',backgroundRepeat:'no-repeat',backgroundPosition:'top'}
           :
           {background:`linear-gradient(72deg,#aeaeae,#ffffff)`,backgroundSize:'200%',animation:'gradient-animation 5s ease-in-out infinite'}
           return i;
        }
    }

}
</script>

<style>
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>