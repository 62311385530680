<template>
    <div v-if="isActive" class="Privacy">
        <!-- <h1>{{title}}</h1> -->
        <div>
            <h1>The {{title}} page is under construction</h1>
            <p>Thank you for your interest. Please check back soon</p>
            <img src="../../../assets/400.gif" alt="">
        </div>
    </div>
</template>
<script>
export default {
    name:'Privacy',
    data(){
        return{
            title:'Privacy',
            isActive:true,
            background:'#EED202'
        }   
    }
}
</script>
<style>
.Privacy{
    width:100%;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(25px);
    background-color: #eed202bf;
    border-radius: inherit;
    color:white;
}
.Privacy div{
    flex-grow:1;
    flex-shrink:0;
}
</style>