<template>
    <div v-if="isActive" :style="{backgroundColor:background}" class="security">
        <h1 class="security-header">{{title}}</h1>
        <div class="security-container">
            <div class="update-profile">
                <button v-on:click="updateProfile()"><h1>Update Profile</h1></button>
            </div>
            <div class="change-password">
                <button v-on:click="resetPassword()"><h1>Change Password</h1></button>
            </div>
            <div class="otp">
                <div class="otp-header"><h1>OTP</h1></div>
                <div class="soon"><h1>Feature Coming soon</h1></div>
                <!-- <div class="email-otp">
                    <div v-if="!phone">
                        <h2>One Time Password (OTP) is sent to your E-mail</h2>
                    </div>
                    <div v-else>
                        <h2>Always send One Time Password (OTP) to your E-mail</h2>
                    </div>
                </div>
                <div class="phone-otp">
                    <div v-if="phone" class="phone-otp-options">
                        <div>
                            Recieve otp via phone
                        </div>
                    </div>
                    <div v-else class="phone-otp-add">
                        <div>
                            Add Phone
                        </div> 
                    </div>
                </div> -->
            </div>
            
            <div class="devices">
                <div class="devices-header"><h1>Devices</h1></div>
                <div class="devices-view">
                    <div v-for="(device,index) in devices" v-bind:key="index" class="device">
                    <template >
                        <div class="device-container">
                            <div class="device-icon">
                                <img src="../../../assets/windows.png" alt="">
                            </div>
                            <div class="device-info">
                                <h3 class="device-type">{{device.os}} {{device.osVersion}}</h3>
                                <h3 class="lastLogin">Last Access : {{lastAccess(device.lastAccess)}}</h3>
                                <h3 v-if="device.current" class="device-status">this device</h3>
                                
                            </div>
                            <button v-if="!device.current" v-on:click="logoutDevice(device.sessions[0])">></button>
                        </div>
                        <!-- <i class="fas fa-tablet"></i> -->
                    </template>
                </div>
                </div>
            </div>
            <!-- <div class="alerts"><h3>Get alerts on logins to new or unrecognised devices </h3></div> -->
            <div class="alerts"><img src="../../../assets/400.gif" alt=""><h3>Parts of this page are still under construction </h3><img src="../../../assets/400.gif" alt=""></div> 
        </div>
    </div>
</template>
<script>
const axios = require('axios')
export default {
    name:'Security',
    mounted(){
        // console.log(this.$keycloak)
        this.getDevices()
    },
    data(){
        return{
            title:'Security',
            isActive:true,
            background:'rgba(63, 77, 90,1)',
            phone:true,
            // background:'rgb(255,255,255)',
            devices:[
                // {
                // Device:'Phone',
                // Status:'Active',
                // lastLogin:new Date(Date.now()).toDateString()
                // },
                // {
                // Device:'Computer',
                // Status:'Inactive',
                // lastLogin: new Date(Date.now()).toDateString()
                // },
                // {
                // Device:'Phone',
                // Status:'Active',
                // lastLogin:new Date(Date.now()).toDateString()
                // },
                // {
                // Device:'Computer',
                // Status:'Inactive',
                // lastLogin: new Date(Date.now()).toDateString()
                // },
                // {
                // Device:'Computer',
                // Status:'Inactive',
                // lastLogin: new Date(Date.now()).toDateString()
                // },
                // {
                // Device:'Computer',
                // Status:'Inactive',
                // lastLogin: new Date(Date.now()).toDateString()
                // },
                // {
                // Device:'Computer',
                // Status:'Inactive',
                // lastLogin: new Date(Date.now()).toDateString()
                // },
                // {
                // Device:'Computer',
                // Status:'Inactive',
                // lastLogin: new Date(Date.now()).toDateString()
                // },
                // {
                // Device:'Computer',
                // Status:'Inactive',
                // lastLogin: new Date(Date.now()).toDateString()
                // }
            ]
        }   
    },
    methods:{
        getDevices(){
            axios.get('https://login.cubeitdone.com/auth/realms/cubeItDone/account/sessions/devices',{
                headers: {
                    Authorization: 'bearer ' + this.$keycloak.token, //the token is a variable which holds the token
                }
            }).then(response => {
                // console.log(response.data)
                this.devices = response.data
            })
            .catch(error=>{
                this.$message(error,'error')
            })
        },
        // logoutDevice(session){
        logoutDevice(){
        //     axios.delete(process.env.VUE_APP_ACCOUNT_URL+"/sessions/"+session.id,{
        //     headers: {
        //         Authorization: 'bearer ' + this.$keycloak.token, //the token is a variable which holds the token
        //         // 'Access-Control-Allow-Methods': "GET, POST, OPTIONS, PUT, DELETE"
        //     }
        // }).then(() => this.getDevices())
        },
        updateEmail(){
            // axios.post(process.env.VUE_APP_ACCOUNT_URL+"/",{
            //     attributes: {locale: ["en"]},
            //     email: "cu@cu.com",
            //     emailVerified: false,
            //     firstName: "cu",
            //     lastName: "cu",
            //     username: "cu"
            // ,
            // })
            // .then(data => {
            //     // console.log(data)
            // })
        },
        resetPassword(){
            window.location = `https://login.cubeitdone.com/auth/realms/cubeItDone/protocol/openid-connect/auth?kc_action=UPDATE_PASSWORD&client_id=Website&redirect_uri=https%3A%2F%2Fprofile.cubeitdone.com%2F&response_mode=fragment&response_type=code&scope=openid`
        },
        updateProfile(){
            window.location = `https://login.cubeitdone.com/auth/realms/cubeItDone/protocol/openid-connect/auth?kc_action=UPDATE_PROFILE&client_id=Website&redirect_uri=https%3A%2F%2Fprofile.cubeitdone.com%2F&response_mode=fragment&response_type=code&scope=openid`
        },
        lastAccess(access){
            var date = new Date(access * 1000)
            return  date.toDateString() +" | "+ date.toLocaleTimeString();
        }
    }
}
</script>
<style scoped>
h1{
    display:flex;
    justify-content: center;
    align-items: center;
    margin:0;
    height:auto;
    width:auto;
    /* padding:1%; */
    color:white;
    /* text-align: end; */
}
h3{
    display:flex;
    align-items: center;
    justify-content: center;
    color: white;
}
img{
    height:100%;
    margin:0;
    width:auto;
    filter:invert(0%);
}
.security{
    height: 100%;
    width:100%;
    position:relative;
    border-radius: inherit;
    background: linear-gradient(220deg, #374556, #000000);
    background-size: 300% 300%;
    background-position: 0 30%;
    -webkit-animation: AnimationName 17s ease infinite;
    -moz-animation: AnimationName 17s ease infinite;
    animation: AnimationName 17s ease infinite;
}
@-webkit-keyframes AnimationName {
    0%{background-position:88% 0%}
    50%{background-position:13% 100%}
    100%{background-position:88% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:88% 0%}
    50%{background-position:13% 100%}
    100%{background-position:88% 0%}
}
@keyframes AnimationName {
    0%{background-position:88% 0%}
    50%{background-position:13% 100%}
    100%{background-position:88% 0%}
}
.security-header{
    height:10%;
}
.security-container{
    height:90%;
    display:grid;
    justify-items:center;
    align-items: center;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto 70% 10%;
    column-gap: 0%;
    row-gap: 1%;
}
.change-password, .update-profile{
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #324052;
    height:100%;
    width:95%;
}
.change-password button, .update-profile button{
    background-color:rgba(0, 0, 0, 0.377);
    width:60%;
    height:50%;
}
.change-password button:hover, .update-profile button:hover{
    background-color: #000000;
}
.otp{
    border-radius: 5px;
    background-color: #324052;
    height:100%;
    width:95%;
}
.otp-header{
    height:10%;
}
.soon{
    height:90%;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
}
.soon h1{
    color:rgba(255, 255, 255, 0.342);
}
.otp-header h1{
    height:100%;
}
.devices{
    border-radius: 5px;
    background-color: #324052;
    height:100%;
    width:95%;

}
.devices-header{
    height:10%;
}
.devices-header h1{
    height:100%;
}
.devices-view{
    width:100%;
    height:90%;
    overflow-x:auto;

}
.device{
    /* background-color:#202831; */
    /* display:inline-flex; */
    width:auto;
    height:20%;
    margin:2%;
}
.device-container{
    background-color:#20283185;
    display:inline-flex;
    width:100%;
    height:100%;
    border-radius:5px;
    
}
.device-container button{
    margin:0;
    height:100%;
    /* width:5%; */
}
.device-icon{
    width:15%;
    height:100%;
    background-color: transparent;
}
.device-info{
    width:85%;
    height:100%;
}
.device-type{
    /* background-color: aliceblue; */
    width:100%;
    height:60%;
    margin:0;
}
.lastLogin{
    display:inline-flex;
    /* background-color: #637790; */
    margin:0;
    height:40%;
    width:75%;
    align-items: center;
    justify-content: center;
    color: #666666;
    letter-spacing: 1px;
}
.device-status{
    display:inline-flex;
    width:20%;
    /* background-color: green; */
    height:40%;
    margin:auto;
    align-items: center;
    justify-content: center;
    color: #666666;
}
.alerts{
    color:white;
    border-radius: 5px 5px 0 0;
    background-color: #324052;
    height:100%;
    width:90%;
    grid-column: 1 / span 2;
    display:flex;
    justify-content: space-between;
}
.alerts h3{
    height:100%;
    margin:0;
}

</style>