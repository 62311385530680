<template>
<transition name="list" appear>
  <div class = "nav">
      <div class="logo">
          <button v-on:click="backHome()" class="logo-img">"</button>
      </div>
      <div class="Home-nav" id="Home-button">
          <button>your Cube Account</button>
      </div>
      <!-- <div class="back">
          <button >--</button>
      </div> -->
  </div>
</transition>
</template>

<script>
export default {
    methods:{
        backHome(){
            window.location="https://cubeitdone.com"
        }
    }
}
</script>

<style>
p{
    margin:0;
}
.nav{
    display: flex;
    flex-flow: row;
    position: relative;
    /* background-color: rgb(255, 0, 0); */
    height:10vh;
    width:100vw;
    /* flex-basis: 0; */
    /* flex:1 0 0px; */
    justify-content: center;
    align-items: center;
    align-content: center;
    /* transition: all 0.5s ease-in-out; */
}
.nav:hover{
    /* height:10vh; */
}

.nav button{
    background-color: transparent;
}

.nav button{
    font-family: neue-haas-unica,sans-serif!important;
    font-style: normal;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    width:99%;
    height:10vh;
    border:none;
    font-size: 16px;
    border-radius: 5px;
    letter-spacing: 3px;
    margin:0;
}
.logo{
    display:inline-block;
    flex: 1 0 0px;
    /* width:15vw; */
    margin:0;
    height:100%;
}
.logo-img{
    background-image: url(../assets/cube-logo.png);
    filter: invert(0%);
    background-size:auto 90%;
    background-repeat: no-repeat;
    background-position: center;
    color:rgba(0,0,0,0);
    border-radius:0px 0px 10px 10px;
}
.logo-img:hover{
    color:transparent;
    background-color: rgb(0, 0, 0);
    /* filter: invert(100%); */
}
.Home-nav{
    display:inline-block;
    flex: 6 0 0px;
    /* width:85vw; */
    margin:0;
    height:100%;
}
.Capabalities{
    display:inline-block;
    flex: 1 0 0px;
    /* width:16.5vw; */
    margin:0;
    height:100%;
}
.Account{
    display:inline-block;
    flex: 1 0 0px;
    /* width:16.5vw; */
    margin:0;
    height:100%;
}
.Support{
    display:inline-block;
    flex: 1 0 0px;
    /* width:16.5vw; */
    margin:0;
    height:100%;
}
.Cart{
    display:inline-block;
    width:10%;
    /* flex: 1 0 0px; */
    margin:0;
    height:100%;
}
.back{
    display:inline-block;
    width:05%;
    /* width:16.5vw; */
    margin:0;
    height:100%;
    transition: all 0.5s ease-in-out;
}

.Nav-selected{
    background-color: rgb(0, 177, 177);
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(199, 199, 199); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #adadad; 
}

</style>