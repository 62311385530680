<template>
    <div class="address-card-add overlay">
        <div class="address-content ">
            <input type="text" autocomplete="name" class="address-name"  style="font-size:30px" v-model="$parent.addAddresses.name" placeholder="Name" >
            <input type="text" autocomplete="address-line1" x-autocompletetype=”address” class="address-line-1" style="font-size:20px" v-model="$parent.addAddresses.line1" placeholder="Line 1">
            <input type="text" autocomplete="address-line2" x-autocompletetype=”address” class="address-line-2" style="font-size:20px" v-model="$parent.addAddresses.line2" placeholder="Line 2">
            <input type="text" autocomplete="address-level2" class="address-city" style="font-size:20px;width:44%;" v-model="$parent.addAddresses.city" placeholder="City">
            <input type="text" autocomplete="postal-code" class="address-pin" style="font-size:20px;width:44.5%;" v-model="$parent.addAddresses.pin" placeholder="Pin Code">
            <input type="text" autocomplete="address-level1" class="address-state" style="font-size:20px" v-model="$parent.addAddresses.state" placeholder="State">
            <input type="text" autocomplete="country-name" class="address-country" style="font-size:20px;" v-model="$parent.addAddresses.country" placeholder="Country">
            <div class="add-address-buttons">
                <button class="add-address-button" v-on:click="$parent.clickAddAddress()" :disabled="$parent.hasAddress">Add</button>
                <button class="add-address-button" v-on:click="$parent.addAddress = !$parent.addAddress">Cancle</button>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.address-card-add{
    display:flex;
    align-items: center;
    justify-content: center;
    /* background-color: rgba(0, 0, 0, 0.137); */
    /* outline:dashed 3px rgba(0, 0, 0, 0.26); */
    height:100%;
    width:100%;
    border-radius: 5px;
    perspective: 250px;
    background: url('https://source.unsplash.com/random/?city+background');
    background-repeat: no-repeat;
    /* background: linear-gradient(90deg, transparent, black); */
    background-blend-mode: screen;
    background-position: center;
    background-size: cover;
    box-shadow: 0 0 10px -5px;
    /* filter:brightness(10); */
    /* box-shadow: inset 0px 0px 60px -40px; */
}
.address-card-add.overlay::after{
    content:"";
    width:100%;
    height:100%;
    position:absolute;
    background-color: rgba(252, 252, 252, 0.35);
    pointer-events: none;
    z-index:-1;
} 
.address-card-add input{
    background-color: rgba(255, 255, 255, 0.561);
}

.add-address-buttons{
    position:absolute;
    right:0%;
    top:105%;
    color:white;
    /* background-color: black; */
    width:50%;
    height:30px;
    display:grid;
    gap:5px;
    grid-template-columns: 1fr 1fr;

}
.add-address-button{
    color:white;
    background-color: black;
    text-align: center;
    margin:2.5%;
    /* width:90%; */
}
</style>