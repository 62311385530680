<template>
  <div id="modal">
    <div class="card-details-container" :style="hasAddress ? {minHeight:'550px'} : {minHeight:'auto'}">
        <div class="card-details">
            <h1>{{cardDetails.name}}</h1>
            <h3>{{cardDetails.phone}}</h3>
            <h3>{{cardDetails.email}}</h3>
            <h5 class="added-on">{{`Added on - ${new Date(cardDetails.created * 1000).toDateString()} ${new Date(cardDetails.created * 1000).toLocaleTimeString()}`}}</h5>
        </div>
        <h2 v-if="hasAddress">Billing Address</h2>
        <div class="card-address">
            <AddressCard v-bind:details="cardDetails.address"></AddressCard>
        </div>
        <div class="remove-card">
            <p v-if="hasDetails">If this card is used to make a payment, these billing details will be used on your invoice.</p>
            <button v-on:click="$parent.removeCard(cardDetails.id);$parent.cardDetails = null">Remove Card</button>
        </div>
    </div>
    <button class="close-modal" v-on:click="$parent.cardDetails = null">Close</button>
  </div>
</template>

<script>
import AddressCard from '../../component/AddressCard.vue'
export default {
  props:{
    cardDetails:{
        type:Object,
        required:true
    }
  },
  components: { AddressCard },
  data(){
    return{
        hasAddress:Object.values(this.cardDetails.address).some(val=>{return val}),
        hasDetails:([this.cardDetails.name,this.cardDetails.email,this.cardDetails.phone].some(val=>{return val}) || this.hasAddress),
        details:{
            name:"Yejneshwar Sivamoorthy",
            email:'yejneshwar@cucircuits.com',
            phone:'+919500082039',
            created:null,
            address:{
                line1:"Sadullah Street",
                line2:"T.nagar",
                city:"Chennai",
                postal_code:"600017",
                state:"Tamil Nadu",
                country:"India",
                img:null
            }
        }
    }
  }
}
</script>

<style>
.card-details-container{
    width:40%;
    min-height:550px;
    /* height:80%; */
    background-color: rgb(206, 206, 206);
    border-radius:7px;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.card-details-container > * :not(.remove-card button){
    pointer-events: none !important;
}
.card-details-container > h2{
    height:auto;
    width:90%;
    margin-bottom:10px;
    margin-top:auto;
    pointer-events: none !important;
}
.card-details{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    /* flex-direction: column; */
    flex-wrap: wrap;
    gap:10px;
    padding-left:30px;
    margin-top:auto;
}


.card-details h1,.card-details h3,.card-details h5{
    height:auto;
    width:auto;
    justify-content: flex-start!important;
    text-align: start;
    margin:5px;
}

.card-details h1{
    width:90%;
}

.card-details .added-on{
    opacity:0.5;
}

.card-address{
    width:90%;
}
.remove-card{
    width:100%;
    margin-top:auto;
}
.remove-card p{
    margin:10px;
    opacity:0.2;
    font-weight:700;
}
.remove-card button{
    width:100%;
    height:50px;
    margin:0;
    background-color: brown;
    color:white;
    border-radius:0px 0px 5px 5px;
}
</style>