<template>
    <div v-if="isActive" class="Payments">
        <AddCard v-if="showModal"></AddCard>
        <CardDetails v-if="cardDetails" v-bind:cardDetails="cardDetails"></CardDetails>
        <!-- <div class="card-grid">
            <template v-for="card in cards" >
                <div v-bind:key="card.id" class="card">
                    {{card.card.last4}}
                </div>
            </template>
        </div> -->
            
        <h1 class="address-header"><div id="message"></div><button class="add-card" v-on:click="addCard()" v-if="cards.length !== 0">Add card</button> {{title}}  </h1>
        <div class="no-card" v-if="cards.length === 0">
                <div class="card">
                    <div class="card-content">
                        <div class="card-brand"></div>
                        <h4 class="card-funding"></h4>
                        <img class="card-chip" :src="getBrandLogo('chip')" alt="card-chip">
                        <h2 class="card-number"></h2>
                        <h4 class="card-holder"></h4>
                        <img class="card-expimg" :src="getBrandLogo('expimg')" alt="card-expimg">
                        <h4 class="card-exp"></h4>
                    </div>
                </div>
                <h3>Looks Like there are no saved cards...</h3>
                <button v-on:click="addCard()" class="add-card">Add card</button>
        </div>
        <div class="cards-container" v-else>
            <div class="cards-grid">
                <div class="card" v-for="(card,index) in cards" v-bind:key="index" :data-brand="card.card.brand">
                    <div class="card-content">
                        <div class="card-extra"><button v-on:click="showDetails(card)"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAAAZUlEQVRYhe3SMQ6AIBBEUWLB0QWv4dmUY/gtpCC4sWLVYl65mUmm2BBERET+BIjAAhRgBzIQvXNtIXOXvHNtoRiF4pmb+o6x67C2jsr1A1aj8MbtUp8m1YfZgPnhuYblREREPnUCvq8S1t95aTkAAAAASUVORK5CYII=" alt=""></button></div>
                        <img class="card-brand" :src="getBrandLogo(card.card.networks.available[0])" :alt="card.card.networks.available[0]">
                        <h4 class="card-funding">{{card.card.funding}}</h4>
                        <img class="card-chip" :src="getBrandLogo('chip')" alt="card-chip">
                        <h2 class="card-number">{{getCardFormat(card.card.networks.available[0],card.card.last4)}}</h2>
                        <h4 class="card-holder">{{card.metadata.name ? card.metadata.name.substring(0, 12) : null }}</h4>
                        <img class="card-expimg" :src="getBrandLogo('expimg')" alt="card-expimg">
                        <h4 class="card-exp">{{getExpiry(card.card.exp_month.toString(),card.card.exp_year.toString())}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AddCard from '../Payment/AddCard.vue'
import brandLogos from '../../../assets/brand-logos/BrandLogos.js'
import CardDetails from '../Payment/CardDetails.vue';
const stripe = window.Stripe(`${process.env.VUE_APP_STRIPE_PUBLIC_API_KEY}`);

// const axios = require("axios")
export default {
    name:"Payment",
  components: { AddCard,CardDetails },
    data(){
        return{
            title:'Payment Methods',
            isActive:true,
            showModal:false,
            cards:[],
            cardDetails:null
        }   
    },
    mounted(){
        var inst = this.$message('LODADING...','loading')
        this.listCards(inst)
        this.checkStatus()
    },
    methods:{
        async checkStatus(){
                     const clientsecret = new URLSearchParams(window.location.search).get(
                        'setup_intent_client_secret'
                    );
                    if(!clientsecret) return;
                    // Retrieve the SetupIntent
                    stripe.retrieveSetupIntent(clientsecret).then(({setupIntent}) => {
                      const message = document.querySelector('#message')
                      setTimeout(()=>{
                        message.innerText = ""
                      },10000)

                      // Inspect the SetupIntent `status` to indicate the status of the payment
                      // to your customer.
                      //
                      // Some payment methods will [immediately succeed or fail][0] upon
                      // confirmation, while others will first enter a `processing` state.
                      //
                      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                      switch (setupIntent.status) {
                        case 'succeeded': {
                          message.innerText = 'Success! Your payment method has been saved.';
                          break;
                        }

                        case 'processing': {
                          message.innerText = "Processing payment details. We'll update you when processing is complete.";
                          break;
                        }

                        case 'requires_payment_method': {
                          message.innerText = 'Failed to process payment details. Please try another payment method.';

                          // Redirect your user back to your payment page to attempt collecting
                          // payment again

                          break;
                        }
                      }
                      window.history.replaceState(null,'','/')
                    });
                },
        addCard(){
            this.showModal = true
        },
        listCards(inst){
            
            this.$payments.get("/cards").then(data=>{
                this.cards = data.data.data
                this.$closeMessage(inst) 
            })
            .catch(error=>{
                if(inst)
                    this.$updateMessage(inst,error,'error')
            })
        },
        getBrandLogo(brand){
            return brandLogos[brand]
        },
        getCardFormat(brand,last){
            if (brand === 'amex'){
                return `**** ****** *${last}`
            }
            return `**** **** **** ${last}`
        },
        getName(metadata){
            return metadata?.name
        },
        getExpiry(month,year){
            if(month.length === 1){
                month = month.padStart(2, "0")
            }
            return `${month} / ${year.slice(-2)}`
        },
        showDetails(card){
            var {id , created , billing_details } = card
            this.cardDetails = {id , created , ...billing_details}
        },
        removeCard(id){
            var inst = this.$message("Loading...",'loading')
            this.$payments.delete('/cards',{data:{id}})
            .then(()=>{
               const index = this.cards.findIndex(card=>{
                    return card.id === id
                })
                this.cards.splice(index,1)
                this.$updateMessage(inst,"Card removed",'success',false,2000)
            })
            .catch(error=>{
                this.$updateMessage(inst,error,'error',false,2000)
            })
        }
    }
}
</script>
<style>
.Payments{
    background: linear-gradient(220deg, #b9b9b9, #ffffff);
    background-size: 300% 300%;
    background-position: 0 30%;
    height:100%;
    width:100%;
    border-radius:inherit;
    position:relative;
    overflow:auto;
}
.add-card{
    background-color: black;
    color:white;
    height:50px;
    width:150px;
}
.no-card{
    height:90%; 
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.no-card .card{
    height:200px;
    width:400px;
    margin-bottom:50px;
}




.no-card .card .card-content > *:not(.card-expimg,.card-chip){
    background-color: rgba(192, 192, 192, 0.62);
    /* padding:5px; */
    border-radius:5px;
    width:80%;
    height:50%; 
}
.cards-container{
    display:flex;
    justify-content: center;
    width:100%;
    height:90%;
    overflow-x: auto;
    /* background-color: rgba(0, 0, 0, 0.123); */
}
.cards-grid{
    display:grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(350px ,1fr));
    grid-auto-rows: 200px;
    grid-gap:3%;
    height:auto;
    width:100%;
    overflow-x: auto;
    padding:50px 5%;
    
}
.card-content h1,.card-content h2,.card-content h3,.card-content h4{
    font-weight:unset;
    margin:0;
    justify-content: center;
    color:white;
    pointer-events: none;
}
.card{
    font-family: 'Orbitron', sans-serif;
    background-color: rgba(16, 24, 38, 0.355);
    position: relative;
    display:flex;
    align-items: center;
    justify-content: center;
    /* background-color: rgba(0, 0, 0, 0.452); */
    height:100%;
    width:100%;
    border-radius: 10px;
    /* background-image: url(../../../assets/local-map2.png); */
    background-repeat: no-repeat;
    /* background:  linear-gradient(0deg, transparent, white 90%) ,url(../../../assets/local-map2.png); */
    /* background-blend-mode: screen; */
    /* background-position: top; */
    box-shadow: 0 0 30px -20px;
    /* filter:brightness(10); */
    /* box-shadow: inset 0px 0px 60px -40px; */
    transition: all 0.5s ease-in-out;

}

.card[data-brand="visa"]{
    background: hsla(190, 68%, 50%, 1);
    background: linear-gradient(90deg, hsla(238, 30%, 32%, 1) 0%, hsla(190, 68%, 50%, 1) 100%);
    background-size: 100%;
}

.card[data-brand="mastercard"]{
    background: hsla(222, 77%, 33%, 1);
    background: linear-gradient(90deg, hsla(222, 77%, 33%, 1) 0%, hsla(235, 100%, 78%, 1) 100%);
    background-size: 100%;
}

.card[data-brand="amex"]{
    background: hsla(165, 60%, 11%, 1);
    background: linear-gradient(90deg, hsla(165, 60%, 11%, 1) 0%, hsla(149, 54%, 31%, 1) 100%);
    background-size: 100%;
}

.card-content{
  display: grid;
  grid-template-areas:
    "more ....  ....   brand"
    "chip    funding   ....   brand"
    "number  number number number"
    "holder  holder expimg exp";
  grid-template-rows: repeat(4, 1fr);



  grid-template-columns: repeat(4, 1fr);
  gap: 1px;
  height:100%;
  width:100%;
  align-items: center;
  justify-items: center;
}


.card-extra {
    grid-area:more;
    justify-self: start;
    padding-left:10px; 
}

.card-extra button{
    background-color: transparent;
    margin:0;
    height: 30px!important;
    opacity:0.2;
}

.card:hover .card-extra button{
    transition:all 0.2s ease-in-out;
    background-color: black;
    opacity:1;
}

.card:hover{
    box-shadow:0px 0px 20px -5px;
    background-size: 200%;
    /* opacity:0.5; */
}


.card-extra button img{
    width:100%;
    height:100%;

    margin:0;
    padding:0;
}

.card-brand{
    width:52px;
    grid-area:brand;
}
.card-funding{
    opacity:0.6;
    grid-area:funding;
    text-transform: uppercase;
    font-weight:900!important;
}
.card-chip{
    border-radius:0;
    width:auto;
    height:35px;
    grid-area:chip;
}
.card-number{
    /* font-size: 19px; */
    letter-spacing: 5px;
    grid-area:number;
    font-weight:600!important;
    
}
.card-holder{
    font-weight:400!important;
    text-transform: uppercase;
    grid-area:holder;
}
.card-expimg{
    border-radius:0;
    margin:0;
    height:25px;
    align-self: start;
    justify-self: end;
    grid-area:expimg;
}
.card-exp{
    height:25px;
    align-self: start;
    justify-self: start;
    display:flex;
    align-items: center;
    grid-area:exp;
}

.card-overlay{
    transition: all 0.1s ease-in-out;
    position:absolute;
    backdrop-filter: blur(0px);
    background-color: rgba(0, 0, 0, 0.418);
    width: 100%;
    height: 100%;
    border-radius:5px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
}
.card-overlay:hover{
    opacity: 1;
    backdrop-filter: blur(2px);
}

</style>