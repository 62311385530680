import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import messages from './components/messages/messages'

import * as Keycloak from 'keycloak-js'
Vue.config.productionTip = false

let initOptions = {
  url: "https://login.cubeitdone.com/auth", realm: 'cubeItDone', clientId: 'Website', onLoad: 'check-sso', responseMode: 'fragment', flow: 'standard', responseType: 'code'

}

let keycloak = Keycloak(initOptions);

keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
  if (!auth) {
    console.log("no auth");
    window.location.replace('https://cubeitdone.com')
  } else {
    Vue.use(messages)
    Vue.prototype.$keycloak = keycloak
    Vue.prototype.$img = Vue.observable({ img: null, cover: null })
    Vue.prototype.$getProfileImage = function () {
      axios.get(`${process.env.VUE_APP_ABOUT_URL}/size/lg`, {
        headers: {
          Authorization: 'bearer ' + this.$keycloak.token, //the token is a variable which holds the token
        }
      })
      .then((data) => {
        Object.keys(data.data).length ? this.$img.img = data.data : this.$img.img = null
      })
    }
    Vue.prototype.$getProfileCover = function () {
      axios.get(`${process.env.VUE_APP_ABOUT_URL}/cover/size/lg`, {
        headers: {
          Authorization: 'bearer ' + this.$keycloak.token, //the token is a variable which holds the token
        }
      })
      .then((data) => {
        Object.keys(data.data).length ? this.$img.cover = data.data : this.$img.cover = null
      })
    }
    Vue.prototype.$getSettings = function(){
      console.log("getting settings")
      axios.get(process.env.VUE_APP_SETTINGS_URL,{ headers: {
                    Authorization: 'bearer ' + this.$keycloak.token, //the token is a variable which holds the token
            }}).then((data)=>{
              document.getElementById('app').style.backgroundImage = `url(${data.data.wallpaper_details.urls.full})`
            }).catch(()=>{
              document.getElementById('app').style.backgroundImage = `url(null)`
              console.log("error")
      })
    }
    Vue.prototype.$payments = axios.create({
        baseURL: process.env.VUE_APP_PAYMENTS_URL,
        headers: {Authorization: 'bearer ' + keycloak.token}
      });
    new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
  }
})
