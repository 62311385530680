<template>
    <div v-if="isActive" class="Apps">
        <div class="apps-header">
            <h1>{{title}}</h1>
        </div>
        <div class="apps-container">
            <template v-for="appType,index in Apps">
                <div class="apps-type" v-bind:key="index">
                <div class="apps-type-header"><h2 class="app-type">{{appType.title}}</h2></div>
                <div class="apps">
                    <div class="app" v-for="app in appType.apps" v-bind:key="app.name">
                        <div class="app-overlay" v-if="!app.status"><h2>Coming Soon</h2></div>
                        <button class="app-button" v-on:click="toSite(app.link)"><img class="icon" :src="app.logo" alt="" v-if="app.logo">{{app.name}}</button>
                    </div>
                </div>
            </div>
            </template>
        </div>
        
    </div>
</template>
<script>
const axios = require('axios')
export default {
    name:"Apps",
    data(){
        return{
            title:'Apps',
            isActive:true,
            Apps:null
        }   
    },
    mounted(){
        this.init()
    },
    methods:{
        init(){
            axios.get(process.env.VUE_APP_APPS_URL).then(data =>{
                // console.log(data.data)
                this.Apps = data.data
            })
        },
        toSite(link){
            if (link != null){
                window.location = 'http://'+link
            }
        }
    }
}
</script>
<style scoped>
button{
    outline: none;
}
h1{
    margin:0;
}
h2{
    margin:0;
    text-align: left;
}
.app-button{
    display:flex;
    justify-content: center;
    align-items: center;
    margin:0;
    width:100%;
    height:100%;
    background-color: transparent;
}
.icon{
    margin:0 20px;
    height:100%;
    /* max-height: 300px; */
    width:auto;
    border-radius:0px;
    box-shadow: 0px 0px 0px;
}
.Apps{
    height: 100%;
    border-radius: inherit;
    background: linear-gradient(220deg, #b9b9b9, #ffffff);
    background-size: 300% 300%;
    background-position: 0 30%;
    -webkit-animation: AnimationName 17s ease infinite;
    -moz-animation: AnimationName 17s ease infinite;
    animation: AnimationName 17s ease infinite;
}
.apps-header{
    display:flex;
    height:10%;
    width:90%;
    justify-content: flex-end;
    align-items: center;
}
.apps-container{
    /* background-color: aquamarine; */
    height:90%;
}
.apps-type{
    height:50%;
    overflow-x: scroll;
}

.apps-type-header{
    /* background-color: black; */
    margin:0px auto;
    width:90%;
    height:20%;
    display:flex;
    align-items: center;
    position:sticky;
    top:0;
    z-index:100;
    backdrop-filter:blur(5px);


}

.app-type{
    width:15%;
    color:#3131318c;
}
.apps {

  width:90%;
  height:80%;
  margin: 0 auto;
  display: grid;
  grid-gap:1rem;
  grid-auto-rows: 100px;
  grid-template-columns: repeat(auto-fill, minmax(350px ,1fr));
  /* display:flex; */
  justify-content: center;
  align-items: top;
}
.app {
    transition: all 0.2s ease-in-out;
  background-color: white;
  height:auto;
  border-radius:5px;
  color: black;
  padding: 1rem;
  box-shadow: 0px 0px 40px -30px;
  position:relative;
}
.app:hover{
    background-color: #e6e6e6;
    box-shadow: 0px 0px 0px -0px;
}

.app:hover .app-overlay{
    display:block;
}

.app-overlay{
    position:absolute;
    background-color: rgb(255, 255, 255);
    border-radius: 2px;
    display:none;
    margin:auto;
    inset:0;
    padding:2%;
    height:fit-content;
    width:fit-content;
    
}
.app-overlay > *{
    margin-top:auto;
    margin-bottom:auto;
    width:100%;
}
</style>